import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { useLocation } from '@reach/router'

import Container from '@objects/container'
import SearchResults from '@components/searchresults'

function SearchResultPage({ data, pageContext }) {
  const { metaTitle } = pageContext.page.metaTags
  const location = useLocation()
  const searchIndex = get(data, 'siteSearchIndex.index')

  const searchquery = useMemo(
    () => queryString.parse(location.hash)?.searchquery,
    [location.hash]
  )

  return (
    <Container ariaLabel="arialabel.searchresults">
      {searchquery && <Helmet title={`${searchquery} | ${metaTitle}`} />}
      <SearchResults searchIndex={searchIndex} />
    </Container>
  )
}

SearchResultPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default SearchResultPage

export const pageQuery = graphql`
  query SearchResultPageIndexQuery {
    siteSearchIndex {
      index
    }
  }
`
